<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <b-container v-else>
      <div>
        <b-col md="8">
          <h1 class="mr-sm-4 header-tablepage">
            Require Column {{ $route.query.page || "Customer" }}
          </h1>
        </b-col>
      </div>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <TableCheckRequireColumnPanel
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :rows="rows"
          :excelFields="excelFields"
          :showingTo="showingTo"
          :pageType="1"
          :form="form"
          @submitForm="submitForm"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import TableCheckRequireColumnPanel from "@/components/customer/TableCheckRequireColumnPanel";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    TableCheckRequireColumnPanel,
    OtherLoading,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Field Name",
        },
        {
          key: "description",
          label: "Field Description",
        },
        {
          key: "is_required",
          label: "Required",
        },
        {
          key: "excelField",
          label: "Excel Field",
        },
      ],
      items: [],
      excelFields: [],
      isBusy: false,
      rows: 0,
      showingTo: 10,
      form: {
        type_import: 1,
        list_map_require: [],
      },
      isLoading: true,
    };
  },
  created() {
    if (!this.$store.state.importFile.responseFileError)
      return this.$router.replace("/customer/importdata");
    this.items =
      this.$store.state.importFile.responseFileError.customer_column_require;
    this.excelFields =
      this.$store.state.importFile.responseFileError.excel_column;
    this.isLoading = false;
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      let reqData = {
        email: this.$store.state.importFile.excelFile.email,
        type_import: this.form.type_import,
        list_map_require: this.form.list_map_require,
        excel_file: this.$store.state.importFile.excelFile.excel_file,
      };
      await this.$store.dispatch("importFileError", reqData);
      const data = this.$store.state.importFile.responseFileError;
      if (data.result === 1) {
        this.isLoading = false;
        this.$store.commit("setResponseFileError", null);
        this.$swal("Import Success!", {
          icon: "success",
        }).then(function () {
          window.location.href = "/report/customer";
        });
      } else {
        this.isLoading = false;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
